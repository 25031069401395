// Create a reference for the Wake Lock.
let wakeLock: WakeLockSentinel | undefined;

const releaseListener = () => {
  // the wake lock has been released
  console.warn("Wake Lock has been released");
  wakeLock = undefined;
  requestWakeLock();
};

// create an async function to request a wake lock
export async function requestWakeLock() {
  if (wakeLock) {
    return wakeLock;
  }

  document.addEventListener("visibilitychange", handleVisibilityChange);

  try {
    wakeLock = await navigator.wakeLock.request("screen");
    console.log("acquired wake lock", { wakeLock });
    wakeLock.addEventListener("release", releaseListener);
  } catch (err: any) {
    // The Wake Lock request has failed - usually system related, such as battery.
    console.error(`${err.name}, ${err.message}`);
  } finally {
    return wakeLock;
  }
}

export function releaseWakeLock() {
  if (wakeLock) {
    console.log("release wake lock", { wakeLock });
    wakeLock.removeEventListener("release", releaseListener);
    wakeLock.release().then(() => {
      console.log("released wake lock", { wakeLock });
      wakeLock = undefined;
      // document.removeEventListener("visibilitychange", handleVisibilityChange);
    });
  }
}

export const handleVisibilityChange = () => {
  if (wakeLock !== null && document.visibilityState === "visible") {
    requestWakeLock();
  }
};
