import * as dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import "./App.css";
import { bell, buzzer, countdown } from "./audio";
import { releaseWakeLock, requestWakeLock } from "./wakelock";

type IntervalType = "warmup" | "interval" | "rest";

function App() {
  const [restTime, setRestTime] = useState(15);
  const [intervalTime, setIntervalTime] = useState(25);
  const [repetitions, setRepetitions] = useState(10);
  const [currentInterval, setCurrentInterval] = useState<IntervalType>();
  const [ticker, setTicker] = useState(0);
  const [repsTicker, setRepsTicker] = useState(0);
  const [lastSetFinish, setLastSetFinish] = useState<number>();

  useEffect(() => {
    if (currentInterval) {
      requestWakeLock();
    } else {
      releaseWakeLock();
    }

    return () => releaseWakeLock();
  }, [currentInterval]);

  const timer =
    currentInterval === "interval"
      ? intervalTime
      : currentInterval === "rest"
      ? restTime
      : 3;

  const reset = useCallback(() => {
    setTicker(0);
    setRepsTicker(0);
    setCurrentInterval(undefined);
    setLastSetFinish(Date.now());
  }, []);

  const start = useCallback(() => {
    if (!currentInterval) {
      setLastSetFinish(undefined);
      setCurrentInterval("warmup");
      countdown.play();
    } else if (repsTicker >= repetitions) {
      bell.play();
      reset();
    } else if (currentInterval === "warmup" || currentInterval === "rest") {
      buzzer.play();
      setCurrentInterval("interval");

      if (repsTicker < repetitions) {
        setRepsTicker((t) => t + 1);
      }
    } else {
      bell.play();
      setCurrentInterval("rest");
    }
  }, [currentInterval, repsTicker, repetitions, reset]);

  useEffect(() => {
    if (currentInterval) {
      const interval = setInterval(() => setTicker((t) => t + 1), 1000);

      if (ticker === timer) {
        setTicker(0);
        start();
      }

      return () => clearInterval(interval);
    }
  }, [currentInterval, ticker, start, timer, repetitions, repsTicker, reset]);

  useEffect(() => {
    if (lastSetFinish) {
      const interval = setInterval(() => setTicker((t) => t + 1), 1000);

      return () => {
        clearInterval(interval);
        setTicker(0);
      };
    }
  }, [lastSetFinish, ticker]);

  return (
    <div className="App">
      <header
        className="App-header"
        style={{
          backgroundColor:
            currentInterval === "interval"
              ? "green"
              : currentInterval === "rest"
              ? "red"
              : currentInterval === "warmup"
              ? "orange"
              : undefined,
        }}
      >
        {currentInterval ? (
          <>
            <p>
              {repsTicker} / {repetitions}
            </p>
            <h1>{timer - ticker}</h1>
          </>
        ) : (
          <>
            <label>
              Interval:
              <button
                onClick={() => setIntervalTime((t) => Math.max(0, t - 5))}
              >
                -5
              </button>
              <input
                type="number"
                min={0}
                value={intervalTime}
                onChange={(event) => setIntervalTime(+event.target.value)}
              />
              <button onClick={() => setIntervalTime((t) => t + 5)}>+5</button>
            </label>
            <label>
              Rust:
              <button onClick={() => setRestTime((t) => Math.max(0, t - 5))}>
                -5
              </button>
              <input
                type="number"
                min={0}
                value={restTime}
                onChange={(event) => setRestTime(+event.target.value)}
              />
              <button onClick={() => setRestTime((t) => t + 5)}>+5</button>
            </label>
            <label>
              Herhalingen:
              <button onClick={() => setRepetitions((t) => Math.max(0, t - 1))}>
                -1
              </button>
              <input
                type="number"
                min={0}
                value={repetitions}
                onChange={(event) => setRepetitions(+event.target.value)}
              />
              <button onClick={() => setRepetitions((t) => t + 1)}>+1</button>
            </label>
          </>
        )}
        {currentInterval ? (
          <button onClick={reset}>Stop</button>
        ) : (
          <button onClick={start}>Start!</button>
        )}
        {lastSetFinish && (
          <h2>
            Pauze {dayjs(Date.now() - lastSetFinish).format("m:ss")}
          </h2>
        )}
      </header>
    </div>
  );
}

export default App;
